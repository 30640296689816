@import "../../../bps/components/form/form.scss";

.form {
  &__text {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightThin;
  }

  &__radio-line {
    min-height: 56px;
  }

  .input__label,
  .select__label {
    font-weight: $fontWeightMedium;
  }

  .radio-input__label,
  .checkbox__label {
    font-weight: $fontWeightRegular;
  }

  &__headline {
    font-weight: $fontWeightBold;
  }

  &__mandatory-text-block {
    font-size: 14px;
  }
}
